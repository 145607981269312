import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/otp",
    name: "otp verification",
    meta: {
      title: "OTP Verification",
    },
    component: () => import("../views/account/otp.vue"),
  },
  {
    path: "/",
    name: "Landing",
    meta: {
      title: "Student Index",
    },
    component: () => import("../pages/Landing.vue"),
  },
  {
    path: "/application",
    name: "Application",
    meta: {
      title: "Application",
    },
    component: () => import("../pages/application/Application"),
  },
  {
    path: "/academic-structure",
    name: "Academic Structure",
    meta: {
      title: "Academic Structure",
    },
    component: () => import("../pages/Academic"),
  },
  {
    path: "/verification",
    name: "Verification",
    meta: {
      title: "Verification",
    },
    component: () => import("../pages/application/Verification"),
  },
  {
    name: "resetPassword",
    path: "/resetpassword",
    meta: {
      title: "Reset Password",
    },
    component: () => import("../views/account/reset-password.vue"),
  },

  {
    path: "/home",
    name: "Student",
    meta: { title: "student", authRequired: true},
    component: () => import("../pages/student"),
    children:[
    {
        path: "",
        name: "home",
        meta: { title: "home", authRequired: true},
        component: () => import("../components/student/home"),
      },
      {
        path: "/courseRegistration",
        name: "courseRegistration",
        meta: { title: "courseRegistration", authRequired: true},
        component: () => import("../components/student/courses/courseRegistration"),
      },
      {
        path: "/exam",
        name: "exam",
        meta: { title: "exam", authRequired: true},
        component: () => import("../components/student/courses/exam"),
      },
      {
        path: "/resitregistration",
        name: "ResitRegistration",
        meta: { title: "ResitRegistration", authRequired: false},
        component: () => import("../components/student/courses/ResitRegistration"),
      },
      {
        path: "/complains",
        name: "complains",
        meta: { title: "complains", authRequired: true},
        component: () => import("../components/student/complains/complains"),
      },
      {
        path: "/classes",
        name: "Classes",
        meta: { title: "Classes", authRequired: true},
        component: () => import("../components/student/classes/Classes"),
      },
      {
        path: "/courses",
        name: "courses",
        meta: { title: "courses", authRequired: true},
        component: () => import("../components/student/courses/courses"),
      },
      {
        path: "/course_single/:id"  ,
        name: "courseSingle",
        meta: { title: "courseSingle", authRequired: true},
        component: () => import("../components/student/courses/courseSingle"),
        children:[
          {
            path:'',
            name:'Course Overview',
            meta: {title:'Course Overview'},
            component: () => import("../components/student/courses/components/Overview.vue"),
          },
          {
            path:'course_notes',
            name:'Course Notes',
            meta: {title:'Course Single'},
            component: () => import("../components/student/courses/components/Notes.vue"),
          }
        ]
      },
      {
        path: "/complainsingle/:id",
        name: "complainsingle",
        meta: { title: "complainsingle", authRequired: true},
        component: () => import("../components/student/complains/complainSingle"),
      },
      {
        path: "/formb",
        name: "formb",
        meta: { title: "Form B", authRequired: true},
        component: () => import("../components/student/courses/formB"),
      },
      {
        path: "/sampleformb",
        name: "sampleformb",
        meta: { title: "Sample Form B", authRequired: true},
        component: () => import("../components/student/courses/sampleFormB"),
      },
      {
        path: "/transaction",
        name: "transaction",
        meta: { title: "Transactions", authRequired: true},
        component: () => import("../components/student/fee/transactions"),
      },
      {
        path: "/payments",
        name: "payment",
        meta: { title: "payments", authRequired: true},
        component: () => import("../components/student/fee/payments"),
      },
      {
        path: "/fee",
        name: "fee",
        meta: { title: "Fee", authRequired: true},
        component: () => import("../components/student/fee/fee"),
      },
      {
        path: "/results",
        name: "results",
        meta: { title: "Results", authRequired: true},
        component: () => import("../components/student/results/results"),
      },
      {
        path: "/camark",
        name: "camark",
        meta: { title: "Ca Mark", authRequired: true},
        component: () => import("../components/student/results/camarks"),
      },
      {
        path: "/transcript",
        name: "transcript",
        meta: { title: "transcript", authRequired: true},
        component: () => import("../components/student/results/transcript"),
      },
      {
        path: "/transcript-application",
        name: "transcript-application",
        meta: { title: "transcript application", authRequired: true},
        component: () => import("../components/student/results/application"),
      },
      {
        path: "/tform",
        name: "tform",
        meta: { title: "transcript form", authRequired: true},
        component: () => import("../components/student/results/applicationform"),
      },
      {
        path: "/settings",
        name: "setting",
        meta: { title: "Settings", authRequired: true},
        component: () => import("../components/student/settings/settings"),
      },
      {
        path: "/notifications",
        name: "notification",
        meta: { title: "notification", authRequired: true},
        component: () => import("../components/student/notifications/notification"),
      },
      {
        path: '/timetable',
        name: 'Time table',
        meta: {
          title: "time table", authRequired: true,
        },
        component: () => import("../components/student/timetable/timetable")
      },
    ],
  },
  
  {
    path: '/advance-ui/sweetalerts',
    name: 'sweetalerts',
    meta: {
      title: "Sweet Alerts", authRequired: true,
    },
    component: () => import('../views/advance-ui/sweetalerts')
  },
  {
    path: '/advance-ui/swiper',
    name: 'Swiper Slider',
    meta: {
      title: "Swiper", authRequired: true,
    },
    component: () => import('../views/advance-ui/swiper')
  },
  {
    path: '/auth/signin-basic',
    name: 'signin-basic',
    meta: {
      title: "Signin Basic", authRequired: true,
    },
    component: () => import('../views/auth/signin/basic')
  },
  {
    path: '/auth/signin-cover',
    name: 'signin-cover',
    meta: {
      title: "Signin Cover", authRequired: true,
    },
    component: () => import('../views/auth/signin/cover')
  },
  {
    path: '/auth/signup-basic',
    name: 'signup-basic',
    meta: {
      title: "Signup Basic", authRequired: true,
    },
    component: () => import('../views/auth/signup/basic')
  },
  {
    path: '/auth/signup-cover',
    name: 'signup-cover',
    meta: {
      title: "Signup Cover", authRequired: true,
    },
    component: () => import('../views/auth/signup/cover')
  },
  {
    path: '/auth/reset-pwd-basic',
    name: 'reset-pwd-basic',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/basic')
  },
  {
    path: '/auth/reset-pwd-cover',
    name: 'reset-pwd-cover',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/cover')
  },
  {
    path: '/auth/create-pwd-basic',
    name: 'create-pwd-basic',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/basic')
  },
  {
    path: '/auth/create-pwd-cover',
    name: 'create-pwd-cover',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/cover')
  },
  {
    path: '/auth/lockscreen-basic',
    name: 'lock-screen-basic',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/basic')
  },
  {
    path: '/auth/lockscreen-cover',
    name: 'lock-screen-cover',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/cover')
  },
  {
    path: '/auth/twostep-basic',
    name: 'twostep-basic',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/basic')
  }, {
    path: '/auth/twostep-cover',
    name: 'twostep-cover',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/cover')
  },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-cover')
  },
  {
    path: '/auth/ofline',
    name: 'ofline',
    meta: {
      title: "Offline", authRequired: true,
    },
    component: () => import('../views/auth/errors/ofline')
  },
  {
    path: '/auth/logout-basic',
    name: 'logout-basic',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/basic')
  },
  {
    path: '/auth/logout-cover',
    name: 'logout-cover',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/cover')
  },
  {
    path: '/auth/success-msg-basic',
    name: 'success-msg-basic',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/basic')
  },
  {
    path: '/auth/success-msg-cover',
    name: 'success-msg-cover',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/cover')
  },
  {
    path: "/maps/google",
    name: "Google Maps",
    meta: {
      title: "Google Maps", authRequired: true,
    },
    component: () => import("../views/maps/google"),
  },
  {
    path: "/maps/leaflet",
    name: "Leaflet Maps",
    meta: { title: "Leaflet Maps", authRequired: true},
    component: () => import("../views/maps/leaflet/index"),
  },
  

];