<template>
  <div>
    <router-view ></router-view>
    <div v-if="loading" class="loader bg-white">

      <div class="">
            <img src="@/assets/images/logo-sm.png" alt="" class="profile-wid-img" />
        </div>
      <b-spinner variant="info" label="Loading..."></b-spinner>
      <p>Please Wait, Getting Information together... </p>
    </div>
    <FloatingWhatsAppButton phoneNumber="+237680394619" message="Greetings Sir, I need some help!" />

  </div>
</template>

<script>
import FloatingWhatsAppButton from './components/whatsapp.vue';

export default {
  name: 'App',
  data() {
    return {
      loading: false
    };
  },

  components: {
    FloatingWhatsAppButton
  },


  created() {

    this.$store.dispatch('school/getSchools')
    this.$store.dispatch('school/getCampusses')
    this.$store.dispatch('school/getDepartments')
    this.$store.dispatch('school/getPrograms')
    this.$store.dispatch('school/getLevels')
    // this.$store.dispatch('school/getAcademicYears')
    const user = sessionStorage.getItem('ENSPSID');
    if (user) {
      
      this.loading = true;
      this.$store.dispatch('student/fetchStudent')
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style>
/* Apply white background to the entire page */
/* body {
  background-color: white;
}

.lsoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
} */


.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Ensure loader covers full screen */
}

</style>
