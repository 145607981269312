<!-- components/GlobalLoader.vue -->
<template>
    <div v-if="loading" class="loader">
        <b-spinner v-if="loading" variant="info" label="Loading..."></b-spinner>
      Loading...
    </div>
  </template>
  
  <script>
  export default {
    computed: {
        
      loading() {
        return this.$store.state.loading.loading;
      }

    }
  };
  </script>
  
  <style>
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  