// import { authHeader } from './auth-header';
import api from '../api'

import axios from 'axios'

import endpoint from '../endpoint';


class UserService{

   async login(email, password) {
    console.log("this is the login  reached");

      return await  axios.post(`${endpoint}/student/login`, {email,password},{headers: { 'Content-Type': 'application/json' }}).then(
    //   return await  axios.post(`${endpoint}/auth/login`, {email,password},{headers: { 'Content-Type': 'application/json' }}).then(
        response => {
        // // login successful if there's a jwt token in the response
        if (response.data.token) {
            // store response details and jwt token in local storage to keep response logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(response.data));
            sessionStorage.setItem('ENSPSID', response.data.token);

        }
        return response.data;
    })
}

    async logout() {
        return await api.post('/auth/logout').then(
            response => {
                localStorage.removeItem('user');
                sessionStorage.removeItem('ENSPSID')
                return response.data
            }
        );
      
    }

    async createUser(user) {
        console.log(user);
        return await api.post(`/auth/register`, {
            name:user.name,
            email:user.email,
            school_id:user.school,
            position:user.position,
            role_id:user.role
        },{headers: { 'Content-Type': 'application/json' }}).then(
            response =>{
                response.data
            }
        )
    }


    async forgotPassword(email){
        return await  axios.post(`${endpoint}/auth/sendResetPasswordCode`, {email},{headers: { 'Content-Type': 'application/json' }}).then(
            response => {
    
            // console.log(response.data)
            return response.data;
        })
    }


    async verifyOTP(code){
        return await  axios.post(`${endpoint}/auth/checkCode`, {code:code.toString()},{headers: { 'Content-Type': 'application/json' }}).then(
            response => {
                localStorage.setItem('enspsid', JSON.stringify(code));
            return response.data;
        })
    }

    async resetPassword(password){
        let code = localStorage.getItem('enspsid');
        return await  axios.post(`${endpoint}/auth/passwordReset`, {code:code.toString(),password:password},{headers: { 'Content-Type': 'application/json' }}).then(
            response => {
            localStorage.removeItem('enspsid');
            return response.data;
        })
    }

    async getAllUsers() {
        return await api.get('/users').then().then(
            response => {
                return response.data
            }
        )
    }

    async getUser(id){
        return await api.get('/user',{id}).then(
            response => {
                return response.data
            }
        )
    }

    async editUser(id){
        return await api.get('/user',{id}).then(
            response => {
                return response.data
            }
        )
    }

    async getRoles(){
        return await api.get('/roles').then(
            response =>{
                return response.data
            }
        )
    }

    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    this.logout();
                    location.reload(true);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            return data;
        });
    }


}

export default new UserService