<template>
    <div class="floating-whatsapp-button" @click="openWhatsApp">
      <i class="ri-whatsapp-fill "></i>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FloatingWhatsAppButton',
    props: {
      phoneNumber: {
        type: String,
        required: true
      },
      message: {
        type: String,
        default: 'Hello, I need some help!' // Optional predefined message
      }
    },
    methods: {
      openWhatsApp() {
        const whatsappUrl = `https://wa.me/${this.phoneNumber}?text=${encodeURIComponent(this.message)}`;
        window.open(whatsappUrl, '_blank');
      }
    }
  };
  </script>
  
  <style scoped>
  .floating-whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #25d366;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 1000;
  }
  
  .floating-whatsapp-button i {
    font-size: 48px;
  }
  </style>
  