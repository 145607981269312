import axios from 'axios';
import api from '../helpers/api';
class SchoolService {

    // all school methods here
    async getAcademicStructure(){
        return await axios.get(`public/academic_structure`).then(
            response => {
                return response.data
            }
        )
    }

    // all school methods here
    async getAcademicYear(){
        return await api.get(`academicYears`).then(
            response => {
                return response.data
            }
        )
    }
    // all school methods here
    async getSchools(){
        return await api.get(`schools`).then(
            response => {
                return response.data
            }
        )
    }

    async saveSchool(school){
        console.log(school);
        return await api.post(`school`,{
            name:school.name,
            tel:school.tel,
            email:school.email,
            address:school.address,
            website:'school.website',
            numOfDepartments:school.numberOfDepartments,
            numOfPrograms:school.numberOfPrograms,
            campus_id:school.campus,
            status:'active'
        }).then(
            response => {
                return response.data
            }
        )
    }

    async editSchool(school){
        return await api.put(`school/${school.id}`, {
            name:school.name,
            tel:school.tel,
            email:school.email,
            address:school.address,
            website:'school.website',
            campus_id:school.campus,
            status:'active'
        }).then(
            response => {
                return response.data
            }
        )
    }

    async getSchool(){
        return await api.get(`campus`).then(
            response => {
                return response.data
            }
        )
    }

    // all department methods
    async getDepartments(){
        return await api.get(`departments`).then(
            response => {
                return response.data
            }
        )
    }


    async saveDepartment(department, school_id){
        return await api.post(`department`,{
            name:department.name,
            short_code:department.short_code,
            description: department.description,
            school_id:school_id,
            tel:department.tel,
            email:department.email
        }).then(
            response => {
                return response.data
            }
        )
    }


    // import departments in an excel file 
    async importDepartments(data){
        return await api.post(`import-departments`,data).then(
            response => {
                return response.data.data;
            }
        )
    }

    async editDepartment(department,school_id){
        console.log(department);
        return await api.put(`department/${department.id}`,{
            name:department.name,
            short_code:department.short_code,
            description:department.description,
            status:department.status,
            school_id:school_id,
            tel:department.tel,
            email:department.email
        }).then(
            response => {
                return response.data
            }
        )
    }

    // all campusses methods
    async getCampusses(){
        return await api.get(`campuses`).then(
            response => {
                return response.data
            }
        )
    }

    async saveCampus(name, address){
        return await api.post(`campus`, {name,address,location:'bamenda'}).then(
            response => {
                return response.data
            }
        )
    }

    async editCampus(id, name,address,status){
        return await api.put(`campus/${id}`,{id,name,address,status}).then(
            response => {
                return response.data
            }
        )
    }

    async getCampus(){
        return await api.get(`campus`).then(
            response => {
                return response.data
            }
        )
    }

    // all program methods
    async getPrograms(){
        return await api.get(`programs`).then(
            response => {
                return response.data
            }
        )
    }
    async saveProgram(form){

        return await api.post(`program`,{
            department_id:form.department,
            name:form.name,
            short_code:form.short_code,
            duration:form.duration,
            degree:form.degree,
            credit:form.credit
        }).then(
            response => {
                return response.data
            }
        )
    }

     // import programs in an excel file 
     async importPrograms(data){
        return await api.post(`import-programs`,data).then(
            response => {
                return response.data.data;
            }
        )
    }


    async editProgram(program, school_id){
        // console.log(program);
        return await api.put(`program/${program.id}`,{
            school_id:school_id,
            department_id:program.department,
            name:program.name,
            short_code:program.short_code,
            duration:program.duration,
            degree:program.degree,
            credit:program.credit
        }).then(
            response => {
                return response.data
            }
        )
    }

    //all options methods
    async getOptions(){
        return await api.get(`options`).then(
            response => {
                return response.data
            }
        )
    }


    async saveOptions(){
        return await api.get(`programs`).then(
            response => {
                return response.data
            }
        )
    }

    async editOptions(){
        return await api.get(`programs`).then(
            response => {
                return response.data
            }
        )
    }

    //all options methods
    async getLevels(){
        return await api.get(`levels`).then(
            response => {
                return response.data
            }
        )
    }

    async saveLevel(level){
        console.log(level);
        return await api.post(`level`,{
            name: level.name,
            shortName: level.shortName,
            department_id: level.department,
            program_id: level.program,
            tuition:level.tuition
            }).then(
            response => {
                return response.data;
            }
        )
    }

        // import LEvels in an excel file 
        async importLevels(data){
            return await api.post(`import-levels`,data).then(
                response => {
                    return response.data.data;
                }
            )
        }

    async editLevel(level,school_id){
        return await api.put(`level/${level.id}`,{
            name: level.name,
            school_id:school_id,
            shortName: level.shortName,
            department_id: level.department,
            program_id: level.program,
            tuition:level.tuition
            }).then(
            response => {
                return response.data
            }
        )
    }
}

export default new SchoolService;