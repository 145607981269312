export let courses = [
    {
        id: 1,
        course: "computer graphics",
        code: "cenp909",
        lecturer: "Fusong  Benadette",
        cv: 3,
    },
    {
        id: 2,
        course: "computer graphics",
        code: "cenp909",
        lecturer: "Fusong  Benadette",
        cv: 3,
    },
    {
        id: 3,
        course: "computer graphics",
        code: "cenp909",
        lecturer: "Fusong  Benadette",
        cv: 3,
    },
    {
        id: 4,
        course: "computer graphics",
        code: "cenp909",
        lecturer: "Fusong  Benadette",
        cv: 3,
    },
    {
        id: 5,
        course: "computer graphics",
        code: "cenp909",
        lecturer: "Fusong  Benadette",
        cv: 3,
    },
    {
        id: 6,
        course: "computer graphics",
        code: "cenp909",
        lecturer: "Fusong  Benadette",
        cv: 3,
    },
    {
        id: 7,
        course: "computer graphics",
        code: "cenp909",
        lecturer: "Fusong  Benadette",
        cv: 3,
    },
    {
        id: 8,
        course: "computer graphics",
        code: "cenp909",
        lecturer: "Fusong  Benadette",
        cv: 3,
    },
]

export let registeredCourses =[
    
    {
        id: 1,
        course: "computer graphics",
        code: "cenp909",
        lecturer: "Fusong  Benadette",
        cv: 1,
    },
    {
        id: 2,
        course: "computer graphics",
        code: "cenp909",
        lecturer: "Fusong  Benadette",
        cv: 3,
    },
    {
        id: 3,
        course: "computer graphics",
        code: "cenp909",
        lecturer: "Fusong  Benadette",
        cv: 2,
    },
    {
        id: 4,
        course: "computer graphics",
        code: "cenp909",
        lecturer: "Fusong  Benadette",
        cv: 3,
    },
]

export let  student = {
    "name":"Ngu Lumfor",
    "lname":"Laura",
    "level": 300,
     "year":"2023/2024",
    "degree" :	"Bsc FST",
    "matricule":"	Uba19p0088",
    "gender" :	"Female",
    "dob" :	"25/03/2001",
    "pob" :	"Mankon Bamenda",
    "roo":	"Mezam",
    "mstatus" :	"Single",
    "passportnumber":	"0000",
    "nationality":	"French",
    "admission":	"10/03/2019",
    "email":	"laurastarr@gmail.com",
    "phone":	"+237672293791",
    "address":	"Ngomgham/Ngomgham",

}

export let complains = [
    {
      id: "1",
      img: require("@/assets/images/companies/img-4.png"),
      name: "Syntyce Solutions",
      description: "Exam marks",
      date: "30 Sep, 2022",
      contacts: "785-685-4616",
      recipient: "785-685-4616",
      type: "Full Time",
      status: "Rejected"
    },
    {
      id: "2",
      img: require("@/assets/images/brands/slack.png"),
      name: "Plunkett Infotech",
      description: "first semester course",
      date: "26 Sep, 2022",
      contacts: "734-544-2407",
      recipient: "734-544-2407",
      type: "Full Time",
      status: "New"
    }, {
      id: "3",
      img: require("@/assets/images/companies/img-4.png"),
      name: "Martin's",
      description: "CA marks",
      date: "26 Sep, 2022",
      contacts: "303-606-1985",
      recipient: "303-606-1985",
      type: "Part Time",
      status: "New"
    }, {
      id: "4",
      img: require("@/assets/images/companies/img-3.png"),
      name: "Meta4Systems",
      description: "CA marks",
      date: "27 Sep, 2022",
      contacts: "610-440-0592",
      recipient: "610-440-0592",
      type: "Part Time",
      status: "Rejected"
    }, {
      id: "5",
      img: require("@/assets/images/companies/img-4.png"),
      name: "Grey Fade",
      description: "level one course",
      date: "28 Sep, 2022",
      contacts: "907-452-3702",
      recipient: "907-452-3702",
      type: "level one course",
      status: "Pending"
    }, 
  ]
  export let complain ={
    name :	"Syntyce Solutions",
    matricule :	"Uba12njnfi",
    date :	"02 Sep, 2022",
    contact:	"785-685-4616",
    description :"A Product Designer of a company is responsible for the user experience of a product, which usually includes taking direction on the business goals and objectives from product management. Product Designers are typically associated with the visual or tactile aspects of a product but sometimes they can also play a role in the information architecture and system design of a product Product designer is an exceptional career choice. The demand for product designers is increasing with each passing day but there is a huge shortage for a skilful product designer in the market. With hard work and an apt skill set, a product designer can make a handsome amount of money.",
}
export let course = [

  { 
    name: "Computer Graphics",
    description: "A Product Designer of a company is responsible for the user experience of a product, which usually includes taking direction on the business goals and objectives from product management. Product Designers are typically associated with the visual or tactile aspects of a product but sometimes they can also play a role in the information architecture and system design of a product  Product designer is an exceptional career choice. The demand for product designers is increasing with each passing day but there is a huge shortage for a skilful product designer in the market. With hard work and an apt skill set, a product designer can make a handsome amount of money.",
    notes:[],
    assignments:[],
    }

    
]

export let results =[
    {
        id:1,
        code:"CENP2103",
        course:"INTRODUCTION TO COMPUTER NETWORKS",
        cv:2,
        ca: 23,
        exam: 70,
        marks: 93,
        gd: 4,
        gp: "2",
        Wp: "4",
    },
    {
        id:2,
        code:"CENP2103",
        course:"INTRODUCTION TO COMPUTER NETWORKS",
        cv:2,
        ca: 23,
        exam: 70,
        marks:93,
        gd: 4,
        gp: "2",
        wp: "4",
    },
    {
        id:3,
        code:"CENP2103",
        course:"INTRODUCTION TO COMPUTER NETWORKS",
        cv:2,
        ca: 23,
        exam: 70,
        marks:93,
        gd: 4,
        gp: "2",
        wp: "4",
    },
    {
        id:4,
        code:"CENP2103",
        course:"INTRODUCTION TO COMPUTER NETWORKS",
        cv:2,
        ca: 23,
        exam: 70,
        marks:93,
        gd: 4,
        gp: "2",
        wp: "4",
    },
    {
        id:5,
        code:"CENP2103",
        course:"INTRODUCTION TO COMPUTER NETWORKS",
        cv:2,
        ca: 23,
        exam: 70,
        marks:93,
        gd: 4,
        gp: "2",
        wp: "4",
    },
    {
        id:6,
        code:"CENP2103",
        course:"INTRODUCTION TO COMPUTER NETWORKS",
        cv:2,
        ca: 23,
        exam: 70,
        marks:93,
        gd: 4,
        gp: "2",
        wp: "4",
    },
    {
        id:7,
        code:"CENP2103",
        course:"INTRODUCTION TO COMPUTER NETWORKS",
        cv:2,
        ca: 23,
        exam: 70,
        marks:93,
        gd: 4,
        gp: "2",
        wp: "4",
    },
]