import courseService from "../../services/course.service";
import studentService from "../../services/student.service"

// const user = JSON.parse(localStorage.getItem('user'));

export const state = {
    student:{},
    courses:[],
    my_courses:[],
    academic_year:{},
    fees:[]
}
export const actions = {

    fetchStudent({commit}){
        return  studentService.getStudent().then(
            data => {
                commit ('SET_STUDENT', data.data);
                commit ('SET_ACADEMIC_YEAR', data.data.academic_year);
                // return Promise.resolve(data);
            }, error => {
                commit('AssetsFailed');
                return Promise.reject(error);
            }
        );
    },

    fetchCourses({commit}, payload){
        return courseService.getCourses(payload).then(
            data => {


                commit('SET_COURSES',data.data.courses);
            }, error => {
                commit('AssetsFailed');
                return Promise.reject(error);
            }
        )
    },

    fetchMyCourses({commit},payload){

        return courseService.getRegisteredCourses(payload)
        .then(
            data => {
                
                commit('SET_MY_COURSES',data.data.courses);
            }, error => {
                commit('AssetsFailed');
                return Promise.reject(error);
            }
        )
    }
}

export const mutations = {
    SET_STUDENT(state,student){
        
        state.student = student
    },
    SET_ACADEMIC_YEAR(state,academic_year){
        
        state.academic_year = academic_year
    },
    SET_COURSES(state,courses){
        state.courses = [];
        state.courses = courses
    },
    SET_MY_COURSES(state,courses){
        state.my_courses = courses
    }
}

export const getters = {

    get_student(state){
        return state.student
    },
    get_academic_year(state){
        return state.academic_year
    },

    get_courses(state){
        return state.courses
    },

    get_my_courses(state){
        return state.my_courses
    }
}