import api from "../helpers/api";
// import {courses} from "../helpers/data"
import {course} from "../helpers/data"
// import  {registeredCourses} from "../helpers/data";
class CourseService{
    async getCourses(payload){
        return await api.get(`courses`,{
          params:{
          // campus_id:payload.student.campus_id,
          // school_id:payload.student.school_id,
          // department_id:payload.student.department_id,
          program_id:payload.student.program_id,
          level_id:payload.level_id,
          semester_id:payload.semester_id,
          

        }}).then(
            response => {
                return response.data
            }
        )
    }

    async getRegisteredCourses(payload){
      return await api.get(`registered_courses`,{ params : {
          say_id:payload.say_id,
          student_id:payload.student.id,
          semester_id:payload.semester_id,
          session:payload.session
        }}).then(
          response => {
              return response.data
          }
      ) 
    }
    
    async courseRegistration(courses,student){
      return await api.post(`course_registration`,{
        courses:courses,
        student_id:student.id,
        say:student.say.id
      }).then(
          response => {
              return response.data
          }
      )
    }

    async resitCourseRegistration(
      courses,
      method,
      payer,
      reference,
      amount,
      number,
      level_id,
      ay_id,
      student_id,
      say
    ){
      // console.log(courses);
      return await api.post(`resit_course_registration`,{
        courses,
        method,
        payer,
        reference,
        amount,
        number,
        level_id,
        ay_id,
        student_id,
        say
      }).then(
          response => {
              return response.data
          }
      )
    }

    // async dropCourse(courses,student){
    //   return await api.delete(`course_registration`,{
    //     courses:courses,
    //     student_id:student.id,
    //     say:student.say.id
    //   }).then(
    //       response => {
    //           return response.data
    //       }
    //   )
    // }


    async getCourse(id){
        return await api.get(`course/${id}`).then(
          response => {
              return response.data
          }
      )
    }

    async getProgramLevels(){
        return  course ;
      // return await api.get(`course`).then(
      //     response => {
      //         return response.data
      //     }
      // )
    }
    async getTimeTable(payload){
      // console.log(payload);
      return await api.get(`get-student-timetable`,{params:{
        department_id:payload.department_id,
        program_id:payload.program_id,
        academic_year_id:payload.academic_year_id}
      }).then(
          response => {
              return response.data
          }
      )
    }

     // Upload Time Table in an pdf file 
     async getNotes(id){
      return await api.get(`get-course-notes`,{params:{
          course_id:id
      }}).then(
          response => {
              return response.data;
          }
      )}

    // Upload Time Table in an pdf file 
    async getClasses(payload){

      return await api.get(`student_classes`,{
        params:{
          student_id:payload.student_id,  
          level_id:payload.level_id
        }
      }).then(
          response => {
            return response.data;
          }
      )
    }

}
export default new CourseService;