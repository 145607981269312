import api from "../helpers/api";
// import {student} from "../helpers/data"
class StudentService{
    async getStudent(){
        //   return  student;
        // console.log();
        return await api.get(`get_student`).then(
            response => {
                return response.data
            }
        )
    }

    // this function creates a new academic year for a student 
    async createSay(level_id,student_id){
        return await api.post(`create_say`,{
          level_id:level_id,
          student_id:student_id,
        }).then(
            response => {
                return response.data
            }
        )
      }
    // this function creates a new academic year for a student 
    async uploadImage(formData){
        return await api.post(`upload-profile-image`,formData).then(
            response => {
                return response.data
            }
        )
      }

}
export default new StudentService;